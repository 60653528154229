<template>
    <div class="social-media">
        <a href="https://www.facebook.com/memodapp" target="_blank" class="network">
            <img src="@assets/img/icons/social/facebook.svg" alt="Facebook">
        </a>
        <a href="http://instagram.com/memodapp" target="_blank" class="network">
            <img src="@assets/img/icons/social/instagram.svg" alt="instagram">
        </a>
        <a href="https://twitter.com/memodapp" target="_blank" class="network">
            <img src="@assets/img/icons/social/twitter.svg" alt="twitter">
        </a>
        <a href="https://www.linkedin.com/company/memo'd/" target="_blank" class="network">
            <img src="@assets/img/icons/social/linkedin.svg" alt="linkedin">
        </a>
        <a href="https://www.youtube.com/channel/UCvCXWvi3yJQTSxAUJjoehBQ" target="_blank" class="network">
            <img src="@assets/img/icons/social/youtube.svg" alt="youtube">
        </a>
        <a href="https://open.spotify.com/show/0yRt4S4QYio2I32neOw18i?si=oJ_dzYALQwarlo8bUxI3DQ&utm_source=copy-link&dl_branch=1" target="_blank" class="network">
            <img src="@assets/img/icons/social/spotify.svg" alt="spotify">
        </a>
    </div>
</template>

<script>
export default {
    name: "SocialMediaIcons"
}
</script>

<style lang="scss" scoped>
.social-media {
    display: flex;

    .network {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;

        &:first-child {
            margin-left: 0;
        }
    }
}
</style>
